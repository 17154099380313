import React from "react";
import style from "./Cancel-subscription.module.css";
import { Card, Col, Row, Divider, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import SubscriptionUsage from "../Subscription-usage/Subscription-usage";
import DeliverabilityTest from "../../Deliverability-test/Deliverability-test";
import { MoreOutlined } from "@ant-design/icons";
// DEFINE MAIN FUNCTION
const CancelSubscription = () => {
    // DEFINE ALL STATES
    const navigate = useNavigate();
    const items = [
        {
            key: "1",
            label: (
                <a rel="Update Billing Details" href="https://billing.stripe.com/p/login/aEUdUQ0xI51J6u4bII">
                    Update Billing Details
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a rel="View Invoices" href="https://billing.stripe.com/p/login/aEUdUQ0xI51J6u4bII">
                    View Invoices
                </a>
            ),
        },
    ];
    // DEFINE MAIN FUNCTION RETURN
    return (
        <>
            <Row gutter={[20]}>
                <Col span={24}>
                    <Card className={style.mainCard}>
                        <Row justify={"space-between"}>
                            <div className={style.cardTitle}>Plan details</div>
                            <div className={style.cardbtn}>
                                <button size="large">Reactivate plan</button>
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottomRight"
                                    arrow
                                >
                                    <div className={style.cardTitleIcon}>
                                        <MoreOutlined />
                                    </div>
                                </Dropdown>
                            </div>
                        </Row>
                        <Divider />
                        <div className={style.titleContainer}>
                            <div>Professional plan</div>
                            <span> · Canceled</span>
                        </div>
                        <Row gutter={[20]}>
                            <Col span={6}>
                                <div className={style.dateContainer}>
                                    <label>Start date</label>
                                    <span>March 21, 2024</span>
                                </div>
                            </Col>
                            <div className={style.dateDivider}></div>

                            <Col span={6}>
                                <div className={style.dateContainer}>
                                    <label>Next Billing Date</label>
                                    <span>April 21, 2024</span>
                                    <label>(Month remaining)</label>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <SubscriptionUsage></SubscriptionUsage>
                <DeliverabilityTest></DeliverabilityTest>
            </Row>
        </>
    );
};
// DEFINE MAIN FUNCTION EXPORT
export default CancelSubscription;
