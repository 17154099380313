import React, { useEffect, useState } from "react";
import style from "./Upgrade-subscription.module.css";
import { Card, Col, Row, Divider, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionUsage from "./Subscription-usage/Subscription-usage";
import DeliverabilityTest from "../Deliverability-test/Deliverability-test";
import { MoreOutlined } from "@ant-design/icons";
import { getItem } from "../../../../utility/localStorageControl";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../../redux/authentication/action";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterWord,
    convertUTCToReadableTime,
    daysRemaining,
    formatDateFromNow,
    getTodayDate,
} from "../../../../config/CommonFuntion";
import { getInvoiceUrl } from "../../../../redux/subscription-plan/action";

// DEFINE MAIN FUNCTION
const UpgradeSubscription = () => {
    // DEFINE ALL STATES
    const navigate = useNavigate();
    const [getData, setgetData] = useState("");
    const [invoiceUrl, setInvoiceUrl] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        if (getItem("userDetails")) {
            setgetData(getItem("userDetails"));
        }
    }, []);
    let userDetails = useSelector((state) => state.auth.userDetails);
    let subscribeUser = userDetails?.subscribed;
    const freePlanExpire = convertUTCToReadableTime(userDetails?.free_trail_expire_at);

    const planType =
        userDetails?.subscriptions?.subscription_type == "lifeTime"
            ? userDetails?.subscriptions?.subscriptionPlan?.subscriptionProduct[0]?.name
            : userDetails?.subscriptions?.subscriptionPlan?.subscription_plan_type;
    console.log(planType);

    const fetchInvoiceUrl = async () => {
        let res = await dispatch(getInvoiceUrl());
        console.log("res", res);
        if (res) {
            setInvoiceUrl(res.billingUrl);
        }
    };
    console.log("invoiceUrl", invoiceUrl);
    useEffect(() => {
        fetchInvoiceUrl();
    }, []);
    const items = [
        {
            key: "1",
            label: (
                <a rel="Update Billing Details" href="https://billing.stripe.com/p/login/aEUdUQ0xI51J6u4bII">
                    Update Billing Details
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <Link rel="View Invoices" target="_blank" to={invoiceUrl}>
                    {invoiceUrl ? "View Invoices" : planType}
                </Link>
            ),
        },
        {
            key: "3",
            label: (
                <Link rel="Cancel Subscription" target="_blank" to="https://billing.stripe.com/p/login/aEUdUQ0xI51J6u4bII">
                    Cancel Subscription
                </Link>
            ),
        },
    ];

    const today = getTodayDate();
    const dayRemaining = daysRemaining(today, freePlanExpire);
    // DEFINE MAIN FUNCTION RETURN
    return (
        <>
            <Row gutter={[20]}>
                <Col span={24}>
                    <Card className={style.mainCard}>
                        <Row justify={"space-between"}>
                            <div className={style.cardTitle}>Plan details</div>
                            <div className={style.cardbtn}>
                                <button
                                    size="large"
                                    onClick={() => {
                                        navigate("/billing-and-subscription/plans");
                                    }}
                                >
                                    {subscribeUser ? "Update Subscription" : "Upgrade"}
                                    {/* Update Subscription */}
                                </button>
                                {subscribeUser && (
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        placement="bottomRight"
                                        arrow
                                    >
                                        <div className={style.cardTitleIcon}>
                                            <MoreOutlined />
                                        </div>
                                    </Dropdown>
                                )}
                            </div>
                        </Row>
                        <Divider />
                        <div
                            className={`${
                                userDetails.free_trial
                                    ? style.titleContainer
                                    : userDetails?.subscriptions?.status == "active"
                                    ? style.titleContainer
                                    : style.freeTrailContainer
                            }`}
                        >
                            <div>
                                {subscribeUser && planType ? capitalizeFirstLetter(planType) : "Free"}{" "}
                                {capitalizeFirstLetter(planType) && capitalizeFirstLetter(planType)?.includes("Plan") ? "" : "plan"}
                            </div>
                            <span>
                                {" "}
                                <label>·</label>
                                {userDetails?.subscribed
                                    ? capitalizeFirstLetterWord(userDetails?.subscriptions?.status)
                                    : userDetails?.free_trial
                                    ? "Active"
                                    : "Free trial ended"}
                            </span>
                        </div>
                        {subscribeUser ? (
                            <Row gutter={[20]}>
                                <Col span={6}>
                                    <div className={style.dateContainer}>
                                        <label>Start date</label>
                                        <span>{moment(getData.subscriptions?.createdAt).format("LL")}</span>
                                    </div>
                                </Col>
                                <div className={style.dateDivider}></div>

                                <Col span={6}>
                                    <div className={style.dateContainer}>
                                        <label>Next Billing Date</label>
                                        <span>
                                            {getData.subscriptions?.next_billing_date ? moment(getData.subscriptions?.next_billing_date).format("LL") : "-"}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <div className={style.cardDescription}>
                                    {userDetails.free_trial ? (
                                        " Once your trial expires, your account will switch to the free plan. Upgrade to continue using Mail Optimal without restrictions"
                                    ) : (
                                        <>
                                            <span>Upgrade now to continue your journey to Mail Optimal</span>
                                        </>
                                    )}
                                </div>
                                {userDetails.free_trial ? (
                                    <div className={style.dateWarn}>
                                        Free trial {userDetails.free_trial ? "expires" : "Expired"} on {freePlanExpire}{" "}
                                        {dayRemaining > 0 ? `( ${dayRemaining}  Day left )` : ""}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                    </Card>
                </Col>
                <SubscriptionUsage></SubscriptionUsage>
                <DeliverabilityTest totalCredit={getData.userCreditCount?.credit_count}></DeliverabilityTest>
            </Row>
        </>
    );
};
// DEFINE MAIN FUNCTION EXPORT
export default UpgradeSubscription;
